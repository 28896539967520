import type { Language } from '@/i18n/types';

export function getBrowserLanguage(): Language | undefined {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const url = new URL(window.location.href);
  let language = url.searchParams.get('language');

  if (!language && navigator.language) {
    language = navigator.language.split('-')[0];
  }

  if (language && ['en', 'pt', 'sp', 'fr'].includes(language)) {
    return language as Language;
  }

  return undefined;
}
