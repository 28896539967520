import { styled } from '@linaria/react';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-flow: column;
  gap: var(--spacing-md);
`;

export const Animation = styled.div`
  height: 160px;
  width: 160px;
`;

export const Footer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: var(--spacing-sm);
`;
