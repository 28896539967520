import { initReactI18next, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';

import { getBrowserLanguage } from './language-detection';
import { getOptions } from './settings';

i18next
  .use(initReactI18next)
  .use(ChainedBackend)
  .init<ChainedBackendOptions>(getOptions(undefined, getBrowserLanguage()));

export const useClientTranslation = useTranslation;
