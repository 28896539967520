import { useCallback, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import lottie, { AnimationItem } from 'lottie-web';

import ThinkingLottieAnimation from '@/@assets/lottie.thinking.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useClientTranslation } from '@/i18n';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';

import { Animation, Content, Footer } from './InvalidEmailLinkModal.styles';

export interface InvalidEmailLinkModalProps {
  open: boolean;
}

export function InvalidEmailLinkModal({ open }: InvalidEmailLinkModalProps) {
  const { t } = useClientTranslation();
  const { authService } = useServices();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const goHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const resendLink = useCallback(async () => {
    try {
      if (!email) {
        throw new Error('Email not provided');
      }

      await authService.sendEmailSignInLink(email);
      toast(t('Email sign in link sent!'), 'root-toast', 'default');
    } catch (error) {
      toast(t('Failed to send email sign in link'), 'root-toast', 'error');
      captureException(error, true);
    }
  }, [authService, email, t]);

  const animationRef = useRef<AnimationItem | null>();
  const lottieRef = useCallback((node: HTMLDivElement | null) => {
    if (node === null) {
      animationRef.current?.destroy();
      animationRef.current = null;
      return;
    }

    const animation = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: ThinkingLottieAnimation,
    });
    animationRef.current = animation;
  }, []);

  return (
    <RMDialog.Root open={open}>
      <RMDialog.Content>
        <RMDialog.Body>
          <Content>
            <Animation ref={lottieRef} />
            <RMText type="sans" size="s" color="on-surface-primary">
              {t('It looks like you’re trying to login with ')}
              <b>{email}</b>
              {t(', but this link was already opened in a different browser.')}
            </RMText>
          </Content>
        </RMDialog.Body>

        <RMDialog.Footer>
          <Footer>
            <RMButton background="neutral" onClick={resendLink} fullWidth>
              {t('Resend')}
            </RMButton>
            <RMButton background="outlined" onClick={goHome} fullWidth>
              {t('Go home')}
            </RMButton>
          </Footer>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
