import { SetStateAction, useCallback, useMemo, useRef, useState } from 'react';

function isFunction<T>(setStateAction: SetStateAction<T>): setStateAction is (prevState: T) => T {
  return typeof setStateAction === 'function';
}

export function useStateRef<T>(initialState: T) {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch: typeof setState = useCallback((setStateAction: SetStateAction<T>) => {
    ref.current = isFunction(setStateAction) ? setStateAction(ref.current) : setStateAction;
    setState(ref.current);
  }, []);

  const value = useMemo(() => {
    return [state, dispatch, ref] as const;
  }, [dispatch, state]);

  return value;
}
