import { InitOptions } from 'i18next';
import { ChainedBackendOptions } from 'i18next-chained-backend';
import LocalStorageBackend, { LocalStorageBackendOptions } from 'i18next-localstorage-backend';
import LocizeBackend, { LocizeBackendOptions } from 'i18next-locize-backend';

export const DEFAULT_NS = 'default';
export const DEFAULT_LNG = 'en';

export function getOptions(ns?: string | string[], lng = DEFAULT_LNG): InitOptions<ChainedBackendOptions> {
  const uniqueNamespaces = new Set([DEFAULT_NS]);

  if (ns) {
    if (Array.isArray(ns)) {
      ns.forEach((otherNs) => uniqueNamespaces.add(otherNs));
    } else {
      uniqueNamespaces.add(ns);
    }
  }

  const projectId = import.meta.env.VITE_LOCIZE_PROJECT;
  if (!projectId) {
    console.warn('Missing NEXT_PUBLIC_LOCIZE_PROJECT env');
  }

  const localStorageOptions: LocalStorageBackendOptions = {
    expirationTime: 60 * 60 * 1000,
    store: typeof window !== 'undefined' ? window.localStorage : null,
  };

  const locizeOptions: LocizeBackendOptions = {
    projectId: projectId ?? '',
    referenceLng: lng,
  };

  return {
    backend: {
      backends: [LocalStorageBackend, LocizeBackend],
      backendOptions: [localStorageOptions, locizeOptions],
    },
    lng,
    ns: Array.from(uniqueNamespaces),
    defaultNS: DEFAULT_NS,
    fallbackNS: DEFAULT_NS,
    fallbackLng: DEFAULT_LNG,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  };
}
